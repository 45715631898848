export const tags = {
  'Readable React': {
    backgroundColor: 'rgba(45, 170, 219, 0.3)',
    color: 'rgb(55, 53, 47)',
  },
  'Testable React': {
    backgroundColor: 'rgba(221, 0, 129, 0.2)',
    color: 'rgb(55, 53, 47)',
  },
};
