import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';
import { BlogWebsiteTag } from '../components/tags';

import './blog.css';

const blogCategories = ['All', 'React', 'JavaScript', 'Testing'];

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  console.log(location.search);
  const searchParams = new URLSearchParams(location.search);
  console.log(searchParams);
  const urlCategory = searchParams.get('category');

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <nav>
        <ul className="blog-nav">
          {blogCategories.map(category => (
            <li key={category}>
              <Link
                className={
                  category.toLowerCase() === urlCategory || (!urlCategory && category === 'All') ? 'active' : ''
                }
                to={`/blog/${category === 'All' ? '' : `?category=${category.toLowerCase()}`}`}
              >
                {category}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <Bio />
      <section style={{ marginTop: rhythm(2) }}>
        {posts
          .filter(({ node }) =>
            !urlCategory ? true : node.frontmatter.tags && node.frontmatter.tags.includes(urlCategory.toLowerCase())
          )
          .map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            return (
              <article key={node.fields.slug}>
                <header>
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link style={{ boxShadow: `none` }} to={`/blog${node.fields.slug}`}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                  {` `}-{` `}
                  <small>{node.timeToRead} minutes</small>
                  {node.frontmatter.website_tags && node.frontmatter.website_tags.length > 0 && (
                    <div className="blogs__blogheader__tags">
                      {node.frontmatter.website_tags.map(tag => (
                        <div className="blogs__blogheader__tags__tag" key={tag}>
                          <BlogWebsiteTag tag={tag} />
                        </div>
                      ))}
                    </div>
                  )}
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </article>
            );
          })}
      </section>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            website_tags
          }
          timeToRead
        }
      }
    }
  }
`;
